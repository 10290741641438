export const COLOR_BLACK = "#262626";
export const COLOR_BLUE = "#2D9CDB";
export const COLOR_MEDIUM_BLUE = "#8E95B3";
export const COLOR_DARK_BLUE = "#263166";
export const COLOR_CYAN = "#28D4FF";
export const COLOR_PURPLE = "#9B51E0";
export const COLOR_LIGHT_PURPLE = "#D389F9";
export const COLOR_RED = "#FF2D55";
export const COLOR_GRAY_1 = "#333333";
export const COLOR_GRAY_2 = "#4F4F4F";
export const COLOR_GRAY_3 = "#828282";
export const COLOR_GRAY_4 = "#BDBDBD";
